<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none text-light">
      <li class="nav-item text-light">
        <b-link class="nav-link text-light" @click="toggleVerticalMenuActive">
          <feather-icon class="text-light" icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">

      <!-- Content Left -->
      <b-col class="content-header-left mb-2" cols="12" md="9">
        <b-row class="breadcrumbs-top h3 text-light">
          <b-col cols="12">
            <div class="breadcrumb-wrapper text-light">
              <b-breadcrumb class="text-light">
                <b-breadcrumb-item to="/home">
                  <feather-icon icon="HomeIcon" size="16" class="align-text-top text-light" />
                </b-breadcrumb-item>
                <b-breadcrumb-item class="text-light" v-for="item in $route.meta.breadcrumb" :key="item.text"
                  :active="item.active" :to="item.to"> <span class="text-light">{{ item.text }}</span>
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-navbar-nav class="nav align-items-center ml-auto text-light">
      <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex text-light">
        <dark-Toggler class="d-none d-lg-block text-light" />
      </div>
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link text-light"
        class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0 text-light">
             {{profil.username}}
            </p>
            <span class="user-status text-light">{{profil.nm_lengkap}}</span>
          </div> 
          <b-avatar size="40" variant="light-primary" badge :src="profil.foto"
            class="badge-minimal" badge-variant="success" />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <router-link to="/profile">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
          </router-link>
        </b-dropdown-item>

       

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center">
          <router-link to="/">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span @click="Logout()">Logout</span>
          </router-link>
         
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import axios from '@/config/Axios';
  // BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import {
    BBreadcrumb,
    BBreadcrumbItem,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  } from 'bootstrap-vue'
  import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

  export default {
    components: {
      ToastificationContent,
      BBreadcrumb,
      BBreadcrumbItem,
      BLink,
      BNavbarNav,
      BNavItemDropdown,
      BDropdownItem,
      BDropdownDivider,
      BAvatar,
      // Navbar Components
      DarkToggler,
    },
    mixins :[Base],
    data(){
      return{
        profil : {}
      }
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
    mounted(){
      this.get_profil();
    },
    methods: {
      async get_profil() {
          const self = this;
          await axios({
            method: 'PUT',
            url: '/api/aut/profil',
            data: {
              
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
            })
            .then(function (response) {
            self.profil = response.data.result;
      
            }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
            });
          },
      Logout() {
        localStorage.setItem("authLogin", false);
        localStorage.setItem("token_auth", "");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Logout',
            icon: 'EditIcon',
            text :'Terimakasih telah menggunakan aplikasi kami, sehat selalu',
            variant: 'info',
          },
        }); 
      }
    }
  }
</script>
