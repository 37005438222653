export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Second Page',
    route: 'second-page',
    icon: 'FileIcon',
  },
  {
    title: 'Second Page ',
    route: 'second-pages',
    icon: 'FileIcon',
  },
  {
    title: 'Dashboards',
    icon: 'HomeIcon', 
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Home',
        route: 'home',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
    ],
  },
]
